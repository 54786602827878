import "../styles/Carousel.css";
import React from "react";
import { Carousel } from "react-bootstrap";
import Vid1 from "../assets/videos/output.mp4";
import Vid2 from "../assets/videos/output.mp4";
import Vid3 from "../assets/videos/output.mp4";
import ReactPlayer from "react-player";
// import "bootstrap/dist/css/bootstrap.css";

const VideoCarousel = () => {
  const videoProperties = [
    {
      id: 1,
      title: "Video 1",
      src: Vid1,
      credit: "Sample 1",
    },
    {
      id: 2,
      title: "Video 2",
      src: Vid2,
      credit: "Sample 2",
    },
    {
      id: 3,
      title: "Video 3",
      src: Vid3,
      credit: "Sample 3",
    },
  ];

  return (
    <div className="carsouelContainer" id="demos">
      <div className="skill-bx wow zoomIn">
        <h1>Demos</h1>
      </div>
      <Carousel>
        {videoProperties.map((videoObj) => {
          return (
            <Carousel.Item key={videoObj.id} style={{ marginBottom: "10px" }}>
              <div
                style={{
                  width: "100%",
                  marginLeft: "300px",
                  marginBottom: "30px",
                }}
                className="player-wrapper"
              >
                <ReactPlayer
                  url={videoObj.src}
                  pip={true}
                  controls={true}
                  playing={true}
                  width="70%"
                  height="100%"
                />
              </div>
              {/* <Carousel.Caption>
                <h3>{videoObj.title}</h3>
                <p>{videoObj.credit}</p>
              </Carousel.Caption> */}
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default VideoCarousel;
